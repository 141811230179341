import React from 'react'

var fetchJsonp = require('fetch-jsonp')

class MeetupSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: null,
      isLoaded: false,
      items: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    fetchJsonp("https://api.meetup.com/find/groups?&sign=true&photo-host=public&zip="+ this.state.value +"&text=amazon&radius=smart&key=7d7371557b23217237503d3a6b12735c")
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({ items: data.data, isLoaded: true });
        console.log(this.state.items);
      });
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <form onSubmit={this.handleSubmit}>
          <label>
            Enter zip code to search amazon meetups near you:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>
      )
    } else {
      return (
        <div>
          <form onSubmit={this.handleSubmit}>
            <label>
              Enter zip code to search amazon meetups near you:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
            <input type="submit" value="Submit" />
          </form>
          <table className="subpage-table">
            <thead>
              <tr>
                <th>name</th>
                <th>description</th>
                <th>location</th>
              </tr>
            </thead>
            <tbody>
              {items.map(item => (
                <tr key={item.id}>
                  <td><a href={item.link + "?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                  <td dangerouslySetInnerHTML={{__html: item.description.substring(0,100) + '...' }} />
                  <td>{item.localized_location}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default MeetupSearchForm
