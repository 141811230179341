import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import MeetupSearchForm from '../components/meetupSearchForm'
import './subpage.css'

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div>

        <Helmet>
          <title>AlphaDex | The Best Amazon Conferences and Meetups</title>
          <meta name="description" content="AlphaDex is your go to spot for finding the best tools and resources for selling on amazon. These are the best conferences and meetups to help you take your amazon skills to the next level." />
        </Helmet>

        <Link to={'/'}>Back</Link>
        <h1>Alphadex preferred conferences and meetups</h1>
        <table className="subpage-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>City</th>
              <th>Month</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {data.allConferencesJson.edges[0].node.conferences.map(( conference , index) => (
              <tr key={index}>
                <td><a href={conference.link + "?ref=thealphadex.com"} target="_blank" rel="noopener noreferrer">{conference.name}</a></td>
                <td><strong className="mobile-show">Type: </strong>{conference.type}</td>
                <td><strong className="mobile-show">City: </strong>{conference.city}</td>
                <td><strong className="mobile-show">Month: </strong>{conference.month}</td>
                <td><strong className="mobile-show">Price: </strong>{conference.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <MeetupSearchForm />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allConferencesJson {
      edges {
        node {
          conferences{
            name
            link
            type
            city
            month
            price
          }
        }
      }
    }
  }
`